import { Box } from "@mui/material";
import { CreateFaultReportBox } from "../CreateFaultReportBox";
import { FaultReportSummary } from "../FaultReportSummary";
import { GeoMapEnersis } from "../GeoMapEnersis/GeoMapEnersis";
import { useGoogleAddressSearch } from "../../context";

interface Props {
  variant?: "create-fault-report" | "summary";
}

export const FaultReportWithMap = (props: Props) => {
  const googleApi = useGoogleAddressSearch();

  const LeftComponent = () =>
    props.variant === "create-fault-report" ? <CreateFaultReportBox /> : <FaultReportSummary />;

  return (
    <Box display="flex" flexDirection="row">
      <Box
        className="test"
        display="flex"
        flexDirection="column"
        gap={"30px"}
        width={"420px"}
        minWidth={"380px"}
        marginRight={"20px"}
        minHeight={"960px"}
      >
        <LeftComponent />
      </Box>
      <GeoMapEnersis
        mapName="TestMap"
        promoteId="number"
        loadOnEntry={true}
        center={[9.14, 48.93919]}
        onDataRefresh={(data) =>
          googleApi.setSearchCenter({
            lat: data.features[0].properties.latitude,
            lng: data.features[0].properties.longitude,
          })
        }
      />
    </Box>
  );
};
